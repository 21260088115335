@import "../../App/scss/appvariables";

.headerWrapper {
  position: relative;
  width: 100%;
  z-index: 10;
}

.headerStickyHeight {
  // This is used by JavaScript to push down the page to make room for the header.
  display: none;
}
.headerStickyShadow {
  box-shadow: rgb(0 0 0 / 12%) 0 1px 3px;
  &.isDarkMode {
    box-shadow: none;
  }
}
.popupWrapper {
  position: relative;
}
.notifyPopup {
  display: block;
  position: absolute;
  left: -17.3rem;
  top: -0.55rem;
  width: 16rem;
  color: #fff;
  background-color: #ef4720;
  padding: 0.5rem 0.5rem 0.5rem 1rem;
  font-size: 0.8rem;
  line-height: 1.4;
  margin-right: 4rem;
  opacity: 1;
  transition: opacity 0.5s ease;
  // transition: background-color 0.2s ease;
  &.hidden {
    opacity: 0;
    transition: opacity 0.5s ease;
  }
  p {
    margin: 0;
    font-weight: 500;
  }
  &:hover {
    cursor: pointer;

    background-color: darken(#ef4720, 10%);
    // transition: background-color 0.2s ease;
    &:before {
      border-color: transparent transparent transparent darken(#ef451f, 10%);
      //   transition: background-color 0.2s ease;
    }
  }
  &:before {
    left: 100%;
    top: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    margin-top: -25px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 25px 8px;
    border-color: transparent transparent transparent #ef451f;
    // transition: background-color 0.2s ease;
  }
  .popupLink {
    color: #fff;
    text-decoration: none;
    &:hover {
      text-decoration: none;
      color: #fff;
    }
  }
}

.header {
  background-color: #080b17;
  height: initial;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1;
  width: 100%;
  overflow: visible !important;
  transition: height 0.2s ease;
  &.isDarkMode,
  &.isLoggedInHome {
    background: transparent;
  }
  &.hideBackground {
    background: transparent;
  }
}
.logoLink {
  &:hover {
    text-decoration: none;
  }
}
.logoWrap {
  overflow: hidden;
  display: flex;
  align-items: center;
  color: #FFF;
  //  width: 4rem;
  @media (min-width: 400px) {
    width: auto;
    overflow: visible;
  }
  .envStatus {
    padding: .2rem 0 .2rem 1rem;
    margin-left: 1.2rem;
    border-left: 1px solid rgba(255,255,255,0.4);
    color: #FF7B7B;
    font-weight: 500;
    white-space: nowrap;
    font-size: 1.1rem;
  }
}
.logo {
  width: 13.5rem;
  // width: 9rem;
  transition: width 0.2s ease;
  @media (min-width: $lg-break) {
    width: 13.5rem;
  }
}

.headerContent {
  padding: 1rem 0;
  min-height: 81.59px;
  flex-wrap: nowrap !important;
}
.menuIcon {
  color: #fff;
  margin: 0;
}

.sticky {
  .header {
    position: fixed;
    top: 0;
    z-index: 1;
    max-width: 100%;
    width: 100%;
    border-bottom: 0;
    background-color: #080b17;
  }
  .logo {
    width: 9rem;
    transition: width 0.2s ease;
  }
  .headerStickyHeight {
    display: block;
  }
}
.navbar {
  justify-content: flex-end;
  display: flex;
  flex-direction: row;
  .navItem {
    flex: 1 1 100%;
    align-self: start;
  }
  .headerButton:first-child {
    margin-right: 1rem;
  }
}
.hide {
  display: none !important;
}
.dashboardButton {
  margin-right: 2rem;
}
