@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: local('Material Icons'),
    local('MaterialIconsRegular'),
    url(./webfonts/icons/MaterialIconsRegular.woff2) format('woff2'),
    url(./webfonts/icons/MaterialIconsRegular.ttf) format('truetype');
}

@font-face {
  font-family: 'Material Icons Round';
  font-style: normal;
  font-weight: 400;
  src: local('Material Icons Round'),
    local('MaterialIconsRound'),
    url(./webfonts/icons/MaterialIconsRound.woff2) format('woff2'),
    url(./webfonts/icons/MaterialIconsRound.otf) format('opentype');
}

@font-face {
  font-family: 'Material Icons Outlined';
  font-style: normal;
  font-weight: 400;
  src: local('Material Icons Outlined'),
    local('MaterialIconsOutlined'),
    url(./webfonts/icons/MaterialIconsOutlined.woff2) format('woff2'),
    url(./webfonts/icons/MaterialIconsOutlined.otf) format('opentype');
}

@font-face {
  font-family: 'Material Icons Sharp';
  font-style: normal;
  font-weight: 400;
  src: local('Material Icons Sharp'),
    local('MaterialIconsSharp'),
    url(./webfonts/icons/MaterialIconsSharp.woff2) format('woff2'),
    url(./webfonts/icons/MaterialIconsSharp.otf) format('opentype');
}

@font-face {
  font-family: 'Material Icons Two Tone';
  font-style: normal;
  font-weight: 400;
  src: local('Material Icons Two Tone'),
    local('MaterialIconsTwoTone'),
    url(./webfonts/icons/MaterialIconsTwoTone.woff2) format('woff2'),
    url(./webfonts/icons/MaterialIconsTwoTone.otf) format('opentype');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
  &.round {
    font-family: 'Material Icons Round';
  }
  &.outlined {
    font-family: 'Material Icons Outlined';
  }
  &.sharp {
    font-family: 'Material Icons Sharp';
  }
  &.twotone {
    font-family: 'Material Icons Two Tone';
  }
}