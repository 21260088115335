@import "../../App/scss/appvariables";

.footer {
  background-color: #080b17;
  position: relative;
  width: 100%;
  bottom: 0;
  //height: 100rem; // needs to be the same as .contentWrapper's bottom padding in root.module.scss
  padding: 2rem 0 0 0;
  color: #fff;
}
.footerCol {
  margin-bottom: 1rem;
}
.footerNav {
  display: flex;
}
.footerButton {
  width: 10rem;
}
.navList {
  list-style: none outside none;
  padding: 0;
  margin: 2rem 0;
  width: 50%;
}
.navItem {
  padding: 0.5rem 0;
}
.heading {
  padding: 0;
  margin: 0 0 0.5rem 0;
  line-height: 1.4;
  font-size: 1.5rem;
  font-weight: 500;
  color: #fff;
}
.footerBottom {
  border-top: #358ab0;
  font-size: 0.8rem;
  padding: 0.5rem 0;
}
.footerLink {
  color: #fff;
  text-decoration: none;
  &.underlined {
    text-decoration: underline;
  }
  &:visited {
    color: #fff;
    text-decoration: none;
  }
  &:hover {
    color: darken(#fff, 20%);
    text-decoration: underline;
  }
}
