@import "appvariables";

:root {
  --ihsi-primary: #2c3657;
  --ihsi-secondary: #00aeef;
  --ihsi-tertiary: #76bc21;
  --ihsi-primary-muted: #3069b0;
  --ihsi-primary-inverse: #f5f5f5;
  --ihsi-gray-light: #ededed;
  --ihsi-gray-medium: #606060;
  --ihsi-gray-default: #555;
  --ihsi-blue: #3396bb;
  --ihsi-blue-dark: #001e61;
  --base-font-color: #555;
  --base-font-color-inverse: #fff;

  --background: white;
  --link-primary: var(--ihsi-primary);
  --link-primary-hover: darken(--ihsi-primary, 20%);
  --text-base-color: $base-font-color;
  --text-primary: var(--base-font-color);
  --text-secondary: var(--ihsi-primary);
  --accent: purple;
}

[data-theme="dark"] {
  --ihsi-primary: #2c3657;
  --ihsi-secondary: #00aeef;
  --ihsi-tertiary: #76bc21;
  --ihsi-primary-muted: #3069b0;
  --ihsi-primary-inverse: #f5f5f5;
  --ihsi-gray-light: #ededed;
  --ihsi-gray-medium: #606060;
  --ihsi-gray-default: #555;
  --ihsi-blue: #3396bb;
  --ihsi-blue-dark: #001e61;

  --background: black;
  --link-primary: #00c1ff;
  --link-primary-hover: darken(var(--link-primary), 20%);
  --text-base-color: $base-font-color-inverse;
  --text-primary: white;
  --text-secondary: grey;
  --accent: darkred;
}
* {
  text-rendering: optimizeLegibility;
  font-optical-sizing: auto;
}
html {
  overflow-y: scroll;
}
html,
body {
  height: 100%;
  box-sizing: border-box;
}

body {
  position: relative;
  display: flex;
  flex-direction: column;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--text-primary);
  min-width: 300px;
  font-weight: 400;
}
p {
  color: var(--text-base-color);
}
a {
  //color: var(--link-primary);
  color: #3b82e8;
  transition: all 0.2s ease;
  text-decoration: none;
}
a:hover,
a:focus {
  //color: darken(#00C1FF, 10%);
  //color: var(--link-primary);
  color: darken(#3b82e8, 10%);
  text-decoration: underline;
  cursor: pointer;
  outline: none;
}
a:hover,
a:active,
a:visited,
a:focus {
  //text-decoration: none;
}

ul {
  // padding-inline-start: 2.5rem;
}

li {
  font-family: $base-font-family;
  // color: $base-font-color;
  font-weight: $base-font-weight-normal;
  font-style: $base-font-style;
  font-size: $base-font-size;
  line-height: $base-line-height;
  // margin-top: 0.625rem;
  // margin-bottom: 0.625rem;
  // list-style-type: square;
}

strong {
  font-weight: bold;
}

ul,
ol {
  // padding: 0 0 0 2.5rem;
  // margin: 0 0 1.5rem 0;
}

ul li,
ol li {
  // margin: 0 0 1rem 0;
  // list-style-position: outside;
}

ul li + li,
ol li + li {
  // margin-top: 0.8em;
}

ol > li {
  // list-style-type: decimal;
}

ol li::before {
  content: none;
}

ol > li > ol {
  // list-style-type: lower-alpha;
}

ol > li > ol > li > ol {
  // list-style-type: lower-roman;
}

button:focus,
input:focus {
  outline: 1px dotted #fff;
}
/* Container from Bootstrap 4 */
.container {
  width: 100%;
  padding-right: 16px;
  padding-left: 16px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

/* from bootstrap 3 */
.clearfix:before,
.clearfix:after,
.dl-horizontal dd:before,
.dl-horizontal dd:after,
.form-horizontal .form-group:before,
.form-horizontal .form-group:after,
.btn-toolbar:before,
.btn-toolbar:after,
.btn-group-vertical > .btn-group:before,
.btn-group-vertical > .btn-group:after,
.nav:before,
.nav:after,
.navbar:before,
.navbar:after,
.navbar-header:before,
.navbar-header:after,
.navbar-collapse:before,
.navbar-collapse:after,
.pager:before,
.pager:after,
.panel-body:before,
.panel-body:after,
.modal-header:before,
.modal-header:after,
.modal-footer:before,
.modal-footer:after {
  display: table;
  content: " ";
}
.clearfix:after,
.dl-horizontal dd:after,
.form-horizontal .form-group:after,
.btn-toolbar:after,
.btn-group-vertical > .btn-group:after,
.nav:after,
.navbar:after,
.navbar-header:after,
.navbar-collapse:after,
.pager:after,
.panel-body:after,
.modal-header:after,
.modal-footer:after {
  clear: both;
}

.flex-container {
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
  flex-wrap: -webkit-wrap;
}

/*
    Safari has bizarre problems with
    the :before/:after elements around divs
    inside flex-boxes. This seems to fix it.
*/
.flex-container:before,
.flex-container:after,
.flex-container.row:before,
.flex-container.row:after {
  content: normal;
}

.flex-container > a {
  padding: 7px;
}
.svg-inline--fa.fa-w-16 {
  width: 1em;
}
.svg-inline--fa {
  height: 1em;
}
.cc-compliance {
  padding-right: 100px !important ;
}

.main {
  min-height: 40vh;
  position: relative;
}
