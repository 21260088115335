@import "../../App/scss/appvariables";
a {
  color: #3b82e8;
  &:hover {
    color: darken(#3b82e8, 20%);
  }
}
.contactSection,
.aboutSection,
.mainSection,
.darkSection {
  display: block;
  padding: 1rem 0 4rem 0;
  margin: 0;
  // background: #e6eef7;
  position: relative;
  z-index: 1;
}
.darkSection {
  background: rgb(35, 43, 75);
  background: -moz-linear-gradient(45deg, rgba(35, 43, 75, 1) 0%, rgba(13, 17, 32, 1) 100%);
  background: -webkit-linear-gradient(45deg, rgba(35, 43, 75, 1) 0%, rgba(13, 17, 32, 1) 100%);
  background: linear-gradient(45deg, rgba(35, 43, 75, 1) 0%, rgba(13, 17, 32, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#232b4b",endColorstr="#0d1120",GradientType=1);
  &:before,
  &:after {
    display: none;
  }
  height: 70vh;
}
.topicSection {
  margin: 0 0 4rem 0;
}
.topicSubsection {
  margin: 0 0 2rem 0;
}
.contactSection {
  &:after {
    display: block;
    content: "";
    background-image: url("../../App/images/line-graph-bg.svg");
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-position: top center;
    background-repeat: repeat-y;
    position: absolute;
    background-size: cover;
    opacity: 0.5;
    z-index: -1;
  }
}
.formCol1 {
  @media (min-width: $lg-break) {
    padding-right: 2rem;
  }
}
.formCol2 {
  @media (min-width: $lg-break) {
    padding-left: 2rem;
  }
}
.mediaList {
  margin: 4rem 0 2rem 0;
  .mediaItem {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 4rem;
    .mediaImg {
      width: 2rem;
      margin-right: 1.5rem;
    }
    .mediaBody {
      font-size: 1rem;
    }
    .mediaLink {
      font-weight: 600;
      font-size: 1rem;
      text-decoration: none;
    }
  }
}
.bulletedList {
  margin: 0 0 1rem 0;
}
.bulletedList li {
  margin: 0.5rem 0;
}
.exampleText {
  font-style: italic;
}
.formCol:first-child {
  padding-right: 0.5rem;
}
.formCol:last-child {
  padding-left: 0.5rem;
}
.aboutImage {
  width: 100%;
  margin: 0 0 2rem 0;
}
.headingLink {
  text-decoration: underline;
}
.searchResults {
  margin: 4rem 0;
}
.infoBlock {
  position: relative;
  padding: 2rem 0;
  &.tall {
    margin-top: 10vh;
    margin-bottom: 10vh;
  }
  &.centered:before,
  &.centered:after {
    left: 50%;
    margin-left: -20%;
  }
  &:before,
  &:after {
    display: block;
    content: "";
    width: 40%;
    height: 3px;
    background-color: #ef4720;
    position: absolute;
    border-radius: 2rem;
  }
  &:before {
    top: 0;
  }
  &:after {
    bottom: 0;
  }
  address {
    margin: 0;
    padding: 0;
  }
  .accentLink {
    font-weight: 400;
    font-size: 1.2rem;
    margin-bottom: 1rem;
    color: #3b82e8;
    &:hover {
      color: darken(#3b82e8, 20%);
      text-decoration: none;
    }
  }
}
.link {
  font-weight: 400;
  color: #3b82e8;
  &:hover {
    color: darken(#3b82e8, 20%);
  }
}
.breadcrumbs {
  margin-top: 0.5rem;
  margin-right: auto;
}
.dashboardSection {
  background-color: #eceef7;
  .avatarSection {
    text-align: center;
    padding: 4rem 0;
  }
  .avatarLogoTitle {
    display: block;
    color: #9cabcb;
    font-size: 0.8rem;
    margin-bottom: 2rem;
  }
}
.dashboardRow {
  margin-left: 0 !important;
  margin-right: 0 !important;
  flex-direction: column;
  @media (min-width: $sm-break) {
    flex-direction: row;
  }
}
.dashboardSidebarCol,
.dashboardMainCol {
  align-items: stretch;
  min-height: 80vh;
}
.dashboardSidebarCol {
  margin-left: 0 !important;
  margin-right: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding: 0;
  margin: 0;
  background-color: #18243c;
  color: #fff;
  @media (min-width: $md-break) {
    // flex: 0 0 250px;
  }
  @media (min-width: $lg-break) {
    // flex: 0 0 auto;
  }
  h1 {
    color: #fff;
  }
}
.dashboardMainCol {
  // flex: 0 0 auto;
}
.sidebarNav {
  .navList {
    list-style: none outside none;
    margin: 0;
    padding: 0;
    text-align: left;
  }
  .navItem {
    margin: 0;
    padding: 0;
  }
  .navItem:first-child {
    .navLink,
    .navLinkActive {
      border-top: 1px solid #626e89;
    }
  }
  // .myDashboardLink,
  // .myDashboardLinkActive {
  //   text-decoration: none;
  //   display: block;
  // }
  // .myDashboardLinkActive {

  // }
  .dashboardLink {
    text-decoration: none !important;
    &:hover {
      text-decoration: none !important;
    }
  }

  .navLink,
  .navLinkActive {
    text-decoration: none;
    display: block;
    padding: 1rem 2rem;
    color: #fff;
    border-bottom: 1px solid #626e89;
    &:hover {
      text-decoration: none;
      background-color: rgba(0, 0, 0, 0.4);
    }
  }
  .navLinkActive {
    background-color: rgba(0, 0, 0, 0.4);
    &:hover {
      background-color: rgba(0, 0, 0, 0.8);
    }
  }
}
code {
  font-size: 0.9rem;
  display: inline-block;
  background: #c1d2e3;
  border-radius: 0.2rem;
  padding: 0 0.3rem;
}
.resultsSection {
  margin-top: 4rem;
  padding-top: 4rem;
  border-top: 1px solid #ced3e0;
}
.faqSubItem {
  margin: 0 0 1rem 0;
}
.hiddenAnchor,
.glossaryAnchor {
  scroll-margin-top: 50px;
}
.hiddenAnchor {
  display: block;
  position: relative;
  top: -50px;
  visibility: hidden;
}
.disclaimerBox {
  //background-color: #e9f3fd;
  //background-color: #fff1db;
  //background-color: #f3f3f3;
  padding: 0.5rem;
  //border: 1px solid #bfbfbf;
  //border: 1px solid #ddc49d;
  border-radius: 0.2rem;
  margin-top: 2rem;
}
.disclaimerLink,
.disclaimerLink:link,
.disclaimerLink:visited,
.disclaimerLink:focus,
.disclaimerLink:active {
  color: #fff;
  text-decoration: underline;
}
.disclaimerLink:hover {
  color: darken(#fff, 20%);
}
