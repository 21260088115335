@import "../../../App/scss/appvariables";

.menuItemBadge {
  background-color: #ef461f;
  color: #fff;
  border-radius: 1rem;
  margin: 0 0 0 0.5rem;
  min-width: 1.35rem;
  min-height: 1.3rem;
  display: inline-block;
  text-align: center;
  font-size: 0.8rem;
  font-weight: 500;
  line-height: 1.3rem;
  padding-top: 0.05rem;
}
.temp {
}
.navLink {
  text-decoration: none;
  display: flex;
  align-items: center;
  color: $ihsi-primary;
  &:hover {
    text-decoration: none;
    color: $ihsi-primary;
  }
}
