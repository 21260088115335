// Note: These styles are app-specific and override the variables.scss styles.

$xs-break: 0;
$sm-break: 576px;
$md-break: 768px;
$lg-break: 992px;
$xl-break: 1200px;

// Color system

$ihsi-primary: #2c3657;
$ihsi-secondary: #00aeef;
$ihsi-tertiary: #76bc21;
$ihsi-accent: #ef4720;
$ihsi-primary-muted: #3069b0;
$ihsi-primary-inverse: #f5f5f5;
$ihsi-gray-light: #ededed;
$ihsi-gray-medium: #606060;
$ihsi-gray-default: #555;
$ihsi-blue: #3396bb;
$ihsi-blue-dark: #001e61;
$ihsi-blue-midnight: #090b17;

$success: #00c851;
$success-pale: #79c195;
$danger: #ff3547;
$danger-pale: #de6868;

// Font Styles (app-level overrides)
//$base-font-size: 1.125rem; // 18px
$base-font-size: 1rem;
$base-font-size-mui: 14;
$base-line-height: 1.4;

$base-mobile-font-size: 0.9rem;
$base-mobile-line-height: 1.3;

$font-weight-lighter: lighter !default;
$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-medium: 600 !default;
$font-weight-semibold: 700 !default;
$font-weight-bold: 800 !default;
$font-weight-bolder: bolder !default;
$font-weight-black: 900 !default;

$font-weight-base: $font-weight-normal !default;
$line-height-base: 1.5 !default;

$display-primary-font-family: "Ubuntu", Arial, sans-serif;
$display-primary-font-weight-semibold: $font-weight-semibold;
$display-primary-font-style: normal;
$display-primary-font-size: $base-font-size;

$base-font-family: "PTSans", Arial, sans-serif;
$base-font-weight-light: $font-weight-light;
$base-font-weight-normal: $font-weight-normal;
$base-font-weight-medium: $font-weight-medium;
$base-font-weight-semibold: $font-weight-semibold;
$base-font-weight-bold: $font-weight-bold;
$base-font-weight-black: $font-weight-black;
$base-font-style: normal;
$base-font-color: #555;

// Base font: 1.25rem
$h1-font-size: $base-font-size * 2.5; // 45px - 2.5
$h2-font-size: $base-font-size * 2; // 36px - 2
$h3-font-size: $base-font-size * 1.555; // 28px - 1.555
$h4-font-size: $base-font-size * 1.225; // 22px - 1.225
$h5-font-size: $base-font-size * 1.225; // 22px 1.225
$h6-font-size: $base-font-size * 1.115; // 20px - 1.115

// Links
//
// Style anchor elements.

$link-color: $ihsi-blue !default;
$link-decoration: none !default;
$link-hover-color: darken($link-color, 15%) !default;
$link-hover-decoration: underline !default;
// Darken percentage for links with `.text-*` class (e.g. `.text-success`)
$emphasized-link-hover-darken-percentage: 15% !default;

//export so we can use them inside of jsx
// please do not remove
:export {
  xsbreak: $xs-break;
  smbreak: $sm-break;
  mdbreak: $md-break;
  lgbreak: $lg-break;
  xlbreak: $xl-break;

  // Color system

  ihsiprimary: $ihsi-primary;
  ihsisecondary: $ihsi-secondary;
  ihsitertiary: $ihsi-tertiary;
  ihsiaccent: $ihsi-accent;
  ihsiprimarymuted: $ihsi-primary-muted;
  ihsiprimaryinverse: $ihsi-primary-inverse;
  ihsigraylight: $ihsi-gray-light;
  ihsigraymedium: $ihsi-gray-medium;
  ihsigraydefault: $ihsi-gray-default;
  ihsiblue: $ihsi-blue;
  ihsibluedark: $ihsi-blue-dark;

  success: $success;
  successpale: $success-pale;
  danger: $danger;
  dangerpale: $danger-pale;

  // Fonts
  basefontsize: $base-font-size;
  basefontsizemui: $base-font-size-mui;
  baselineheight: $base-line-height;

  basemobilefontsize: $base-mobile-font-size;
  basemobilelineheight: $base-mobile-line-height;

  fontweightlighter: $font-weight-lighter;
  fontweightlight: $font-weight-light;
  fontweightnormal: $font-weight-normal;
  fontweightmedium: $font-weight-medium;
  fontweightsemibold: $font-weight-semibold;
  fontweightbold: $font-weight-bold;
  fontweightbolder: $font-weight-bolder;
  fontweightblack: $font-weight-black;

  fontweightbase: $font-weight-base;
  lineheightbase: $line-height-base;

  displayprimaryfontfamily: $display-primary-font-family;
  displayprimaryfontweightsemibold: $display-primary-font-weight-semibold;
  displayprimaryfontstyle: $display-primary-font-style;
  displayprimaryfontsize: $display-primary-font-size;

  basefontfamily: $base-font-family;
  basefontweightlight: $base-font-weight-light;
  basefontweightnormal: $base-font-weight-normal;
  basefontweightmedium: $base-font-weight-medium;
  basefontweightsemibold: $base-font-weight-semibold;
  basefontweightbold: $base-font-weight-bold;
  basefontweightblack: $base-font-weight-black;
  basefontstyle: $base-font-style;
  basefontcolor: $base-font-color;

  // Base font: 1.25rem
  h1fontsize: $h1-font-size;
  h2fontsize: $h2-font-size;
  h3fontsize: $h3-font-size;
  h4fontsize: $h4-font-size;
  h5fontsize: $h5-font-size;
  h6fontsize: $h6-font-size;
}
