@import "../../App/scss/appvariables";

$borderWidth: 20px;
$backgroundColor: #1a1f2e;
$heroBackgroundColor: #0d1120;
.buttonRounded,
.heroButton {
  padding: 0.3rem;
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: $borderWidth;
  z-index: 1;
  color: #fff;
  text-transform: uppercase;
  text-decoration: none !important;
  &:hover {
    text-decoration: none !important;
    color: #fff;
    &::before {
      background-color: lighten($backgroundColor, 5%);
      transition: background-color 0.2s ease;
    }
    &::after {
      background-position: 100% 50%;
      animation: animateGradientIn 0.2s ease alternate;
    }
  }
  &:before {
    content: "";
    position: absolute;
    background-color: $backgroundColor;
    width: 100%;
    height: 100%;
    display: flex;
    z-index: 0;
    border-radius: $borderWidth;
  }
  &:after {
    content: "";
    position: absolute;
    display: flex;
    top: calc(-0.15 * $borderWidth);
    left: calc(-0.15 * $borderWidth);
    height: calc(100% + $borderWidth * 0.3);
    width: calc(100% + $borderWidth * 0.3);
    //background: linear-gradient(60deg, #f79533, #f37055, #ef4e7b, #a166ab, #5073b8, #1098ad, #07b39b, #6fba82);
    background: linear-gradient(60deg, #a166ab, #5073b8, #1098ad, #07b39b, #6fba82);
    border-radius: calc(2 * $borderWidth);
    z-index: -1;
    animation: animateGradientOut 0.2s ease alternate;
    background-size: 300% 300%;
  }
  &.borderless {
    &:before,
    &:after {
      background: transparent;
      transition: background-color 0.2s ease;
    }
  }
  &.borderless:hover {
    &:before,
    &:after {
      background: lighten($backgroundColor, 10%);
      transition: background-color 0.2s ease;
    }
  }
  .buttonLabel {
    text-transform: uppercase;
    position: relative;
    z-index: 2;
    color: #fff;
    padding: 0 1rem;
    white-space: nowrap;
  }
  &.reversed .buttonLabel {
    color: #fff;
  }
  &.centered {
    width: auto;
    margin: 0 auto;
  }
}

.heroButton {
  padding: 1rem;
  max-width: 15rem;
  margin: 0 auto;
  text-decoration: none;
  &:hover {
    text-decoration: none;
    color: #fff;
    &::before {
      background-color: lighten($heroBackgroundColor, 5%);
      transition: background-color 0.2s ease;
    }
    &::after {
      background-position: 100% 50%;
      animation: animateGradientIn 0.2s ease alternate;
    }
  }
  &:before {
    content: "";
    position: absolute;
    background-color: $heroBackgroundColor;
    width: 100%;
    height: 100%;
    display: flex;
    z-index: 0;
    border-radius: 30px;
    text-decoration: none;
  }
  &:after {
    text-decoration: none;
    content: "";
    position: absolute;
    display: flex;
    top: calc(-0.15 * $borderWidth);
    left: calc(-0.15 * $borderWidth);
    height: calc(100% + $borderWidth * 0.3);
    width: calc(100% + $borderWidth * 0.3);
    //background: linear-gradient(60deg, #f79533, #f37055, #ef4e7b, #a166ab, #5073b8, #1098ad, #07b39b, #6fba82);
    background: linear-gradient(60deg, #a166ab, #5073b8, #1098ad, #07b39b, #6fba82);
    border-radius: calc(2 * $borderWidth);
    z-index: -1;
    animation: animateGradientOut 0.2s ease alternate;
    background-size: 300% 300%;
  }
}

@keyframes animateGradientIn {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}
@keyframes animateGradientOut {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
