.breadcrumbs {
  margin: 0 0 4rem 0;
}
.bcLink {
  color: #2c3657;
  &:hover {
    color: #2c3657;
    text-decoration: underline;
  }
}
