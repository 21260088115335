.pageContainer {
  display: block;
  flex-direction: initial;
  min-height: 80vh;
  position: relative;
  background-color: #fff;
  // overflow: hidden;
  // position: relative;
  &:before,
  &:after {
    display: none;
  }
  .contentWrap {
    //padding-bottom: 10rem;
    z-index: 1;
  }
  &.isDarkMode {
    display: flex;
    flex-direction: column;
    background: rgb(35, 43, 75);
    background: -moz-linear-gradient(45deg, rgba(35, 43, 75, 1) 0%, rgba(13, 17, 32, 1) 100%);
    background: -webkit-linear-gradient(45deg, rgba(35, 43, 75, 1) 0%, rgba(13, 17, 32, 1) 100%);
    background: linear-gradient(45deg, rgba(35, 43, 75, 1) 0%, rgba(13, 17, 32, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#232b4b",endColorstr="#0d1120",GradientType=1);
    &:before {
      display: block;
      content: "";
      background-image: url("../../App/images/wavy-line-2.svg");
      width: 100%;
      height: 100vh;
      top: 0;
      left: 0;
      background-position: 60% 100%;
      background-repeat: no-repeat;
      position: absolute;
      background-size: 2051px;
      opacity: 0.5;
    }
    &:after {
      display: block;
      content: "";
      background-image: url("../../App/images/circle-bg.svg");
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      opacity: 12%;
      background-position: -1050px -300px;
      //background-position: 120% -300px;
      background-repeat: no-repeat;
      position: absolute;
      background-size: 2200px;
    }
  }
  &.isLoggedInHome {
    background: rgb(35, 43, 75);
    background: -moz-linear-gradient(45deg, rgba(35, 43, 75, 1) 0%, rgba(13, 17, 32, 1) 100%);
    background: -webkit-linear-gradient(45deg, rgba(35, 43, 75, 1) 0%, rgba(13, 17, 32, 1) 100%);
    background: linear-gradient(45deg, rgba(35, 43, 75, 1) 0%, rgba(13, 17, 32, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#232b4b",endColorstr="#0d1120",GradientType=1);
    &:before,
    &:after {
      display: none;
    }
    &.isDarkMode {
      &:before {
        display: block;
        content: "";
        background-image: url("../../App/images/wavy-line-2.svg");
        width: 100%;
        height: 100vh;
        top: 0;
        left: 0;
        background-position: 60% 100%;
        background-repeat: no-repeat;
        position: absolute;
        background-size: 2051px;
        opacity: 0.5;
      }
      &:after {
        display: block;
        content: "";
        background-image: url("../../App/images/circle-bg.svg");
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        opacity: 12%;
        background-position: -1050px -300px;
        //background-position: 120% -300px;
        background-repeat: no-repeat;
        position: absolute;
        background-size: 2200px;
      }
    }
  }
}
.notifyBox {
  padding: 1rem;
  text-align: center;
  background-color: #168e1e;
  margin-bottom: 2rem;
  h4 {
    text-align: center;
    line-height: 1;
    margin: 0;
    padding: 0;
    color: #fff;
  }
}
.headerDisclaimer {
  padding: 1rem;
  background-color: rgb(239, 70, 31);
  color: #fff;
  .closeLink {
    color: #fff;
    text-decoration: underline;
    margin: 0 0 0 1rem;
  }
}
