.exportDisclaimer {
  padding: 1rem;
  background-color: #ef4720;
  color: #fff;
  margin-bottom: 1rem;
  a,
  a:link,
  a:visited,
  a:hover,
  a:active {
    color: #fff;
    text-decoration: underline;
  }
}
