.advancedSearchStatusBar {
  position: fixed;
  padding: 1rem;
  background-color: #e9f3fd;
  //background-color: #fff;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  border-top: 1px solid #b0c3d5;
  bottom: 0;
  //box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.1);
  transition-property: bottom;
  transition-duration: 0.3s;
  transition-delay: 0.3s;
  &.hide {
    bottom: -5rem;
    transition-property: bottom;
    transition-duration: 0s;
    transition-delay: 0s;
  }
}
$color-code-background: #2d5379;
$color-code-color: #f8f8f2;
.searchSyntax {
  font-size: 0.9rem;
  font-weight: 600;
  pre {
    background-color: $color-code-background;
    color: $color-code-color;
    padding: 5px;
    border-radius: 0.3em;
    margin: 0;
    font-weight: bold;
    white-space: wrap !important;
  }
}
