@import "../../App/scss/appvariables";

.contactSection {
  margin: 0;
}
.contactForm {
  margin: 0 0 2rem 0;
}
.formCol:first-child {
  padding-right: 0.5rem;
}
.formCol:last-child {
  padding-left: 0.5rem;
}
.emailImg {
  margin: 0 auto 2rem auto;
  max-width: 6rem;
}
.alertBox {
  display: block;
  color: #FFF;
  background-color: #142035;
  border: 1px solid #566278;
  border-radius: 4px;
  text-align: center;
  padding: 0.5rem;
  margin: 0 0 2rem 0;
  &.error {
    background-color: #c21919;
    border: 1px solid #c21919;
  }
  &.small {
    font-size: 0.9rem;
  }
}
.loadingInline {
  position: relative;
  display: inline-block;
  margin: 0.5rem 0 0 0;
}
.alertBox {
  display: block;
  visibility: hidden;
  background-color: #142035;
  border: 1px solid #566278;
  border-radius: 4px;
  text-align: center;
  padding: 0.5rem;
  margin: 0 0 3rem 0;
  &.error {
    background-color: #861d1d;
    border: 1px solid #861d1d;
  }
  &.visible {
    visibility: visible;
  }
  &.topPosition {
    margin: 4rem 0 -2rem 0;
  }
  &.small {
    font-size: 0.9rem;
  }
}
.centerBox {
  margin-top: 15rem;
}
.successImg {
  width: 3rem;
  margin-bottom: 1rem;
}
